import revive_payload_client_F9ku05_FpubbqoJpp96Dm7nWDehTbmc9j3FrTd7uIno from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_177d5bbd7be411328903a55e2c05f64f/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Wn6dif_bZZmNF4Qs0llA_ScdXvVCN0LGtGQQwOzCnR8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_177d5bbd7be411328903a55e2c05f64f/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_WMu9Aif1Izp11AKt6oB6t90FCBnTXsOGUFRUWYIpJL8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_177d5bbd7be411328903a55e2c05f64f/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_92n7s_caZhwB5dyDACki0A_vSesWFD0nJe8LgjvCE6Q from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.1.4_magicast@0.3.5_vue@3.5.13_typescript@5.6.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_jjC8qJaiznxoipUpLqBYhuqoEGgvHzqsjTagvoiPVGw from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_177d5bbd7be411328903a55e2c05f64f/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_D_08t2475IyaOEolDWJPySE2lKHwZd7yDefalOz0oFk from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_177d5bbd7be411328903a55e2c05f64f/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GD227ryzxWq_NAz1aFd2ELuYETP8SOyYjVb7JwrE4Xg from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_177d5bbd7be411328903a55e2c05f64f/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_bOijy3UcMcNNCfaztKzVygtfheEaHz1pqUNF7WqRWkQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_177d5bbd7be411328903a55e2c05f64f/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/vercel/path0/docs/.nuxt/components.plugin.mjs";
import prefetch_client_LS7fzj1Fzxv4jsLh6WRoO1RBWOv_sFw8kKRSajjhZBE from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_177d5bbd7be411328903a55e2c05f64f/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_4AfSMyEbNGyA_pNFo6JuOnzFkUKJLeMZ8slzfKxG1KU from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.2.2_@types+node@22.13.14_jiti@2.4.2_sass@1.86.0_5efd0d970f111c83adc4594aa595d568/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import documentDriven_96CTUcPmtuYkgnXpadVqf_6Pgrb7qfPpYbEmTPp_8II from "/vercel/path0/node_modules/.pnpm/@nuxt+content@2.13.4_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_nuxt@3.16.1_@parcel+watcher_dbb89a268a24df25777ac2de513e849d/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import theme_client_rfbVbgWaCwi7ZJMGerocuZgluqwuXtmrKBv2vWIBFuU from "/vercel/path0/packages/nuxt/src/runtime/plugins/theme.client.ts";
import plugin_client_sQsoN3ShCY3w7TTUhDo6ZgZURKOa9c7Ot_XTJsCGlO0 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII from "/vercel/path0/docs/.nuxt/unocss.mjs";
import analytics_client_D__0bhX73uC0vhcsTxv5CvOp30_GkDRY3Sfq7hDlz_U from "/vercel/path0/docs/plugins/analytics.client.ts";
export default [
  revive_payload_client_F9ku05_FpubbqoJpp96Dm7nWDehTbmc9j3FrTd7uIno,
  unhead_Wn6dif_bZZmNF4Qs0llA_ScdXvVCN0LGtGQQwOzCnR8,
  router_WMu9Aif1Izp11AKt6oB6t90FCBnTXsOGUFRUWYIpJL8,
  _0_siteConfig_92n7s_caZhwB5dyDACki0A_vSesWFD0nJe8LgjvCE6Q,
  payload_client_jjC8qJaiznxoipUpLqBYhuqoEGgvHzqsjTagvoiPVGw,
  navigation_repaint_client_D_08t2475IyaOEolDWJPySE2lKHwZd7yDefalOz0oFk,
  check_outdated_build_client_GD227ryzxWq_NAz1aFd2ELuYETP8SOyYjVb7JwrE4Xg,
  chunk_reload_client_bOijy3UcMcNNCfaztKzVygtfheEaHz1pqUNF7WqRWkQ,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_LS7fzj1Fzxv4jsLh6WRoO1RBWOv_sFw8kKRSajjhZBE,
  plugin_4AfSMyEbNGyA_pNFo6JuOnzFkUKJLeMZ8slzfKxG1KU,
  documentDriven_96CTUcPmtuYkgnXpadVqf_6Pgrb7qfPpYbEmTPp_8II,
  theme_client_rfbVbgWaCwi7ZJMGerocuZgluqwuXtmrKBv2vWIBFuU,
  plugin_client_sQsoN3ShCY3w7TTUhDo6ZgZURKOa9c7Ot_XTJsCGlO0,
  unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII,
  analytics_client_D__0bhX73uC0vhcsTxv5CvOp30_GkDRY3Sfq7hDlz_U
]