<template>
  <div class="flex flex-wrap items-center gap-4">
    <NSwitch
      loading
      default-value
    />

    <NSwitch
      default-value
      switch="green"
      loading
    />

    <NSwitch
      default-value
      size="lg"
      loading
      switch-checked="info"
    />

    <NSwitch
      default-value
      loading
      size="7"
      switch-checked="pink"
    />

    <NSwitch
      default-value
      loading
      size="8"
      switch-checked="purple"
    />

    <NSwitch
      default-value
      loading
      switch-checked="warning"
    />
  </div>
</template>
