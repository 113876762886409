
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexSFZrr5eezLmedHjBspW5AKYvvKac2A_pvQ41mjv7YQkMeta } from "/vercel/path0/node_modules/.pnpm/@una-ui+content@45.1.0_@parcel+watcher@2.5.1_@types+node@22.13.14_@unhead+vue@2.0.0_vue_87885a705764c4304d1c2837a78423e5/node_modules/@una-ui/content/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93wFeolzVv1Ah85A17Eutkc42AfioCnolI4l0_Ggdk28UMeta } from "/vercel/path0/node_modules/.pnpm/@una-ui+content@45.1.0_@parcel+watcher@2.5.1_@types+node@22.13.14_@unhead+vue@2.0.0_vue_87885a705764c4304d1c2837a78423e5/node_modules/@una-ui/content/pages/[...slug].vue?macro=true";
import { default as sidebarnL0RwrzOVWykYmT3RQXaLS_45M8ky9QR1UZm5l8nOGrLcMeta } from "/vercel/path0/docs/pages/blocks-renderer/sidebar.vue?macro=true";
import { default as collapsible_45nested_45sidebarF5_dgLn_45315KgTkrbvTzo1BpL1h_45azyDS_dwMOrc_45VkMeta } from "/vercel/path0/docs/pages/blocks-renderer/collapsible-nested-sidebar.vue?macro=true";
import { default as blocks_45rendererlDF4d8xfabQW3JBadj_SCYUpthO0EnfkABcajcK6Q_450Meta } from "/vercel/path0/node_modules/.pnpm/@una-ui+content@45.1.0_@parcel+watcher@2.5.1_@types+node@22.13.14_@unhead+vue@2.0.0_vue_87885a705764c4304d1c2837a78423e5/node_modules/@una-ui/content/pages/blocks-renderer.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/node_modules/.pnpm/@una-ui+content@45.1.0_@parcel+watcher@2.5.1_@types+node@22.13.14_@unhead+vue@2.0.0_vue_87885a705764c4304d1c2837a78423e5/node_modules/@una-ui/content/pages/index.vue")
  },
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/node_modules/.pnpm/@una-ui+content@45.1.0_@parcel+watcher@2.5.1_@types+node@22.13.14_@unhead+vue@2.0.0_vue_87885a705764c4304d1c2837a78423e5/node_modules/@una-ui/content/pages/[...slug].vue")
  },
  {
    name: "blocks-renderer",
    path: "/blocks-renderer",
    meta: blocks_45rendererlDF4d8xfabQW3JBadj_SCYUpthO0EnfkABcajcK6Q_450Meta || {},
    component: () => import("/vercel/path0/node_modules/.pnpm/@una-ui+content@45.1.0_@parcel+watcher@2.5.1_@types+node@22.13.14_@unhead+vue@2.0.0_vue_87885a705764c4304d1c2837a78423e5/node_modules/@una-ui/content/pages/blocks-renderer.vue"),
    children: [
  {
    name: "blocks-renderer-sidebar",
    path: "sidebar",
    meta: sidebarnL0RwrzOVWykYmT3RQXaLS_45M8ky9QR1UZm5l8nOGrLcMeta || {},
    component: () => import("/vercel/path0/docs/pages/blocks-renderer/sidebar.vue")
  },
  {
    name: "blocks-renderer-collapsible-nested-sidebar",
    path: "collapsible-nested-sidebar",
    component: () => import("/vercel/path0/docs/pages/blocks-renderer/collapsible-nested-sidebar.vue")
  }
]
  }
]