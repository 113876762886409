<template>
  <div class="flex flex-wrap items-center gap-4">
    <NSwitch
      default-value
      switch-checked="primary"
    />

    <NSwitch
      default-value
      switch-checked="green"
    />

    <NSwitch
      default-value
      outset
      switch-checked="info"
    />

    <NSwitch
      default-value
      switch-checked="pink"
    />

    <NSwitch
      default-value
      outset
      switch-checked="purple"
    />

    <NSwitch
      default-value
      switch-checked="warning"
    />
  </div>
</template>
