<template>
  <NSidebarProvider>
    <AppSidebar />

    <main>
      <NSidebarTrigger />
      <NuxtPage />
    </main>
  </NSidebarProvider>
</template>
