<template>
  <NSidebar>
    <template #header>
      <!-- header area -->
    </template>

    <template #content>
      <NSidebarGroup />
      <NSidebarGroup />
    </template>

    <template #footer>
      <!-- footer area -->
    </template>
  </NSidebar>
</template>
