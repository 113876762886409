<template>
  <div class="flex items-center justify-center">
    <div class="w-full overflow-hidden sm:w-300px">
      <NAspectRatio
        :ratio="16 / 9"
      >
        <img
          src="https://i.pinimg.com/736x/bd/02/93/bd0293d5ec30a515aa241ec302812187.jpg"
          alt="Abstract image"
          class="object-cover"
        >
      </NAspectRatio>
    </div>
  </div>
</template>
