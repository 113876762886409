<template>
  <NSidebar>
    <NSidebarHeader>
      <!-- header area -->
    </NSidebarHeader>

    <NSidebarContent>
      <NSidebarGroup />
      <NSidebarGroup />
    </NSidebarContent>

    <NSidebarFooter>
      <!-- footer area -->
    </NSidebarFooter>
  </NSidebar>
</template>
